<template>
  <div>
    <div class="row mx-0">
      <ActivityEngagementTable
        :activities="activityList"
        :activityStats="activityStats"
        :rooms="projectRooms"
        :loadingComplete="loadingComplete"
        :error="error"
      >
      </ActivityEngagementTable>
    </div>
  </div>
</template>

<script>
import { mapGetters } from  "vuex";
import {
  POPULATE_ACTIVITY_LIST,
  POPULATE_ACTIVITY_STATS
} from "@/core/services/store/activity/activity.module";
import { POPULATE_PROJECT_ROOMS_LIST } from "@/core/services/store/project/project_room.module";
import {
  POPULATE_ACTIVITY_TYPES,
  POPULATE_ACTIVITY_STAGES
} from "@/core/services/store/system/system.module";

import LoadingCompleteHelper from "@/helpers/LoadingCompleteHelper";

import ActivityEngagementTable from "@/view/content/review/ActivityEngagementTable";
export default {
  name: "ActivityEngagement",

  data() {
    return {
      pageLoader: {
        componentsRequired: 5,
        componentsCompleted: 0
      },
      error: {
        active: false,
        message: ""
      },
    }
  },

  components: {
    ActivityEngagementTable
  },

  mounted() {
    this.fetchActivityTypes();
    this.fetchActivityStages();
    this.fetchProjectRooms();
    this.fetchActivities();
    this.fetchActivityStats();
  },

  methods: {
    fetchActivityTypes: function() {
      this.$store
        .dispatch(POPULATE_ACTIVITY_TYPES, false)
        .then(() => {
          this.pageLoader.componentsCompleted++;
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the available activity types, please check back later or contact the helpdesk";
        });
    },
    fetchActivityStages: function() {
      this.$store
        .dispatch(POPULATE_ACTIVITY_STAGES, false)
        .then(() => {
          this.pageLoader.componentsCompleted++;
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the available activity stages, please check back later or contact the helpdesk";
        });
    },
    fetchProjectRooms: function() {
      let payload = {
        projectId: this.projectInfo.id,
        params: {
          paginate: false,
          projectRooms: 'with',
          activities: 'withCount'
        }
      };
      this.$store
        .dispatch(POPULATE_PROJECT_ROOMS_LIST, payload)
        .then(() => {
          this.pageLoader.componentsCompleted++;
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the project rooms list, please check back later or contact the helpdesk";
        });
    },
    fetchActivities: function() {
      let payload = {
        projectId: this.projectInfo.id,
        params: {
          paginate: false,
          activityType: 'with',
          activityStage: 'with',
          projectRoom: 'with'
        }
      };
      this.$store
        .dispatch(POPULATE_ACTIVITY_LIST, payload)
        .then(() => {
          this.pageLoader.componentsCompleted++;
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the activity list, please check back later or contact the helpdesk";
        });
    },
    fetchActivityStats() {
      let payload = {
        projectId: this.projectInfo.id
      };
      this.$store
        .dispatch(POPULATE_ACTIVITY_STATS, payload)
        .then(() => {
          this.pageLoader.componentsCompleted++;
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the activity stats, please check back later or contact the helpdesk";
        });
    },
  },

  computed: {
    ...mapGetters([
      'projectInfo',
      'activityList',
      'activityStats',
      'projectRooms'
    ]),
    loadingComplete: function() {
      return LoadingCompleteHelper.loadingComplete(this.pageLoader, this.error);
    }
  }
};
</script>
