<template>
  <tr>
    <td class="pt-5">
      <i
        :class="icon"
        v-b-tooltip.hover.right
        :title="$t(iconLabel)"
      >
      </i>
    </td>
    <td class="px-2 py-3 d-table-cell">
      <ColumnActivityTitle
        :activity="activity"
        :value="activityTitle"
        goTo="stream"
      >
      </ColumnActivityTitle>
      <span
        class="text-muted font-weight-bold text-muted d-block font-size-sm"
      >
        {{ activitySubtitle }}
        <span>{{ activityStatus.startPrefix }}</span>
        <GenericDatetimeDisplay
          :long="true"
          :date="activity.starts_on"
          :lastUpdated="false"
          :relative="false"
        >
        </GenericDatetimeDisplay>
        <span>{{ activityStatus.endPrefix }}</span>
        <GenericDatetimeDisplay
          :long="true"
          :date="activity.ends_on"
          :lastUpdated="false"
          :relative="false"
        >
        </GenericDatetimeDisplay>
      </span
      >
    </td>
    <td class="text-center py-5 font-size-sm">
      <i
        class="far fa-clock"
        :class="activityStatus.stageClass"
        v-b-tooltip.hover.right
        :title="activityStatus.tooltip"
      >
      </i>
    </td>
    <td class="text-center py-5 font-size-sm">
      <ColumnResponseCount
        :activity="activity"
        :value="activityStats.initial_response_count"
      >
      </ColumnResponseCount>
    </td>
    <td class="text-center py-5 font-size-sm">
      <ColumnNewCount
        :activity="activity"
        :value="activityStats.total_new"
      >
      </ColumnNewCount>
    </td>
    <td class="text-center py-5 font-size-sm">
      <ColumnFavouriteCount
        :activity="activity"
        :value="activityStats.total_favourites"
      >
      </ColumnFavouriteCount>
    </td>
    <td class="text-center py-5 font-size-sm">
      <ColumnNoteCount
        :activity="activity"
        :value="activityStats.total_notes"
      >
      </ColumnNoteCount>
    </td>
    <td class="text-center py-5 font-size-sm">
      <ColumnReplyCount
        :activity="activity"
        :value="activityStats.reply_response_count"
      >
      </ColumnReplyCount>
    </td>
    <td class="text-center py-5 font-size-sm">
      <ColumnTagCount
        :activity="activity"
        :value="activityStats.total_tags"
      >
      </ColumnTagCount>
    </td>
    <td class="px-5 py-6">
      <ParticipantResponseRate
        :responseRate="activityStats"
        :activity="activity"
      >
      </ParticipantResponseRate>
    </td>
  </tr>
</template>

<script>
import ParticipantResponseRate from "@/view/components/ParticipantResponseRate";
import ColumnActivityTitle from "@/view/components/table-columns/general/ActivityTitle";
import ColumnResponseCount from "@/view/components/table-columns/activity_posts/InitialCount";
import ColumnNewCount from "@/view/components/table-columns/activity_posts/NewCount";
import ColumnFavouriteCount from "@/view/components/table-columns/activity_engagement/FavouriteCount";
import ColumnNoteCount from "@/view/components/table-columns/activity_engagement/NoteCount";
import ColumnReplyCount from "@/view/components/table-columns/activity_engagement/ReplyCount";
import ColumnTagCount from "@/view/components/table-columns/activity_engagement/TagCount";
import GenericDatetimeDisplay from "@/modules/together-helpers/components/generic-displays/GenericDatetimeDisplay";

import SystemIconHelper from "@/modules/together-helpers/helpers/SystemIconHelper";
import SystemLabelHelper from "@/modules/together-helpers/helpers/SystemLabelHelper";

export default {
  name: "ActivityEngagementRow",

  props: {
    activity: { type: Object, required: true },
    activityStats: { type: Object, required: true }
  },

  components: {
    ParticipantResponseRate,
    ColumnActivityTitle,
    ColumnResponseCount,
    ColumnNewCount,
    ColumnFavouriteCount,
    ColumnNoteCount,
    ColumnReplyCount,
    ColumnTagCount,
    GenericDatetimeDisplay
  },

  computed: {
    icon: function() {
      return SystemIconHelper.activityType(this.activity.activity_type, this.activity.recording_override);
    },
    iconLabel: function() {
      return SystemLabelHelper.activityType(this.activity.activity_type, this.activity.recording_override);
    },
    activityTitle: function() {
      let title = null;

      switch(this.activity.activity_stage.name) {
        case 'Draft':
          title = `[${this.$t("SYSTEM.ACTIVITY_STAGE.DRAFT")}] ` + this.activity.title;
          break;
        case 'Published':
        case 'Active':
          title = this.activity.title;
          break;
        case 'Closed':
          title = `[${this.$t("SYSTEM.ACTIVITY_STAGE.CLOSED")}] ` + this.activity.title;
          break;
      }

      return title;
    },
    activitySubtitle: function() {
      let type = this.activity.activity_type.name.toUpperCase().replace(/ /g,"_");
      type = `${this.$t("SYSTEM.ACTIVITY_TYPE." + type)}`;

      return type
    },
    activityStatus: function() {
      let tooltip = "";
      let stageClass = "";
      let start = "";
      let end = "";
      switch(this.activity.activity_stage.name) {
        case 'Draft':
          tooltip = `${this.$t("SYSTEM.ACTIVITY_STAGE.DRAFT")}`;
          stageClass = "text-warning";
          start = " | " + `${this.$t("GENERAL.PREFIX.STARTS_ON")}: `;
          end = " | " + `${this.$t("GENERAL.PREFIX.ENDS_ON")}: `;
          break;
        case 'Published':
          tooltip = `${this.$t("SYSTEM.ACTIVITY_STAGE.PUBLISHED")}`;
          stageClass = "text-info";
          start = " | " + `${this.$t("GENERAL.PREFIX.STARTS_ON")}: `;
          end = " | " + `${this.$t("GENERAL.PREFIX.ENDS_ON")}: `;
          break;
        case 'Active':
          tooltip = `${this.$t("SYSTEM.ACTIVITY_STAGE.ACTIVE")}`;
          stageClass = "text-success";
          start = " | " + `${this.$t("GENERAL.PREFIX.STARTED_ON")}: `;
          end = " | " + `${this.$t("GENERAL.PREFIX.ENDS_ON")}: `;
          break;
        case 'Closed':
          `${this.$t("SYSTEM.ACTIVITY_STAGE.CLOSED")}`;
          stageClass = "text-danger";
          start = " | " + `${this.$t("GENERAL.PREFIX.STARTED_ON")}: `;
          end = " | " + `${this.$t("GENERAL.PREFIX.ENDED_ON")}: `;
          break;
      }
      return {
        tooltip: tooltip,
        stageClass: stageClass,
        startPrefix: start,
        endPrefix: end
      };
    },
    progressClass: function() {
      let progress = 50;
      if(progress >= 80) {
        return "bg-success";
      } else if (progress < 20) {
        return "bg-danger";
      } else {
        return "bg-warning";
      }
    },
    progressTooltip: function() {
      //return activityStats.participant_repsonse_rate + "% " +  `${this.$t("GENERAL.PROGRESS.RESPONSE_RATE")}` + " (" + activityStats.initial_response_count + "/" + activityStats.total_participants + ")";
      return "Holding";
    }
  }
};
</script>
