<template>
  <tr>
    <td></td>
    <td class="text-center">
      <p class="mb-2 font-weight-bolder">{{ name }}</p>
    </td>
    <td colspan="8"></td>
  </tr>
</template>

<script>
export default {
  name: "RoomEngagementRow",

  props: {
    name: { type: String, required: true }
  }
};
</script>
