<template>
  <div class="col-xl-12 px-0">
    <div
      class="card card-custom card-stretch gutter-b"
      :class="error.active ? 'bordered-card-danger' : 'bordered-card-primary'"
    >
      <div class="card-header border-0 py-5" v-if="loadingComplete && !error.active">
        <h3 class="card-title align-items-start flex-column col-xxl-5 px-0">
          <div class="form-group mb-0 col-xxl-10 px-0">
            <input
              id="activity_engagement_search"
              v-model="search"
              type="text"
              :placeholder="$t('GENERAL.INPUT.SEARCH')"
              class="form-control form-control-solid h-auto py-3 px-5"
              :disabled="loadingComplete == false"
            />
          </div>
        </h3>
        <div class="card-toolbar">
          <FilterSystemActivityType
            class="mr-3"
            :selected="filters.type"
            @type_changed="filters.type = $event"
          >
          </FilterSystemActivityType>
          <FilterSystemActivityStage
            :selected="filters.stage"
            @stage_changed="filters.stage = $event"
          >
          </FilterSystemActivityStage>
        </div>
      </div>
      <div class="card-body py-0">
        <b-spinner
          v-if="loadingComplete == false && !error.active"
          variant="primary"
          label="Spinning"
          class="card-loader"
        >
        </b-spinner>
        <div
          v-if="loadingComplete && !error.active"
          class="table-responsive"
        >
          <table
            id="activity_engagement_table"
            class="table table-head-custom table-vertical center table-hover"
            style="table-layout:auto; overflow: auto"
          >
            <thead>
				      <tr>
					      <th class="px-2 py-3" rowspan="2" style="width:30px"></th>
					      <th class="px-2 py-3" rowspan="2" style="min-width:380px">
                  {{ $t('TABLES.ACTIVITY_HEADERS.ACTIVITY') }}
                </th>
					      <th class="px-2 py-3 text-center" rowspan="2" style="width:70px; max-width:90px">
                  {{ $t('TABLES.ACTIVITY_HEADERS.STATUS') }}
                </th>
					      <th colspan="2" class="border-bottom-0 py-2 px-4 posts text-center">
                  {{ $t('TABLES.ACTIVITY_HEADERS.POSTS') }}
                </th>
								<th colspan="4" class="border-bottom-0 py-2 px-4 notes text-center">
                  {{ $t('TABLES.ACTIVITY_HEADERS.ENGAGEMENT') }}
                </th>
                <th class="px-5 py-3 text-center" rowspan="2" style="width:320px">
                  {{ $t('TABLES.ACTIVITY_HEADERS.PARTICIPANT_RESPONSE_RATE') }}
                </th>
				      </tr>
              <tr>
					      <th class="px-2 pb-3 pt-2 sub posts text-center" style="width:80px; max-width:90px">
                  {{ $t('TABLES.ACTIVITY_HEADERS.TOTAL') }}
                </th>
					      <th class="px-2 pb-3 pt-2 sub posts text-center" style="width:80px; max-width:90px">
                  {{ $t('TABLES.ACTIVITY_HEADERS.NEW') }}
                </th>
								<th class="px-2 pb-3 pt-2 sub notes text-center" style="width:100px; max-width:110px">
                  {{ $t('TABLES.ACTIVITY_HEADERS.FAVOURITED') }}
                </th>					
								<th class="px-2 pb-3 pt-2 sub notes text-center" style="width:80px; max-width:90px">
                  {{ $t('TABLES.ACTIVITY_HEADERS.NOTES') }}
                </th>
						    <th class="px-2 pb-3 pt-2 sub notes text-center" style="width:80px; max-width:90px">
                  {{ $t('TABLES.ACTIVITY_HEADERS.REPLIES') }}
                </th>
						    <th class="px-2 pb-3 pt-2 sub notes text-center" style="width:80px; max-width:90px">
                  {{ $t('TABLES.ACTIVITY_HEADERS.TAGGED') }}
                </th>		
				      </tr>
			      </thead>
            <tbody>
              <template v-for="(activity, i) in activities">
                <ActivityEngagementRow
                  v-if="activity.project_room_id == null &&
                    activityInSearch(activity) &&
                    activityInTypeFilter(activity) &&
                    activityInStageFilter(activity) &&
                    loadingComplete"
                  :key="'empty' + i"
                  :activity="activity"
                  :activityStats="activityStats[activity.id]"
                >
                </ActivityEngagementRow>
              </template>
              <template v-for="(room, i) in nonEmptyRooms">
                <RoomEngagementRow
                  v-if="loadingComplete && activities.length > 0 && filters.type == null && filters.stage == null"
                  :key="i"
                  :name="room.title"
                >
                </RoomEngagementRow>
                <template v-for="(activity, a) in activities">
                  <ActivityEngagementRow
                    v-if="activity.project_room_id == room.id &&
                      activityInSearch(activity) &&
                      activityInTypeFilter(activity) &&
                      activityInStageFilter(activity) &&
                      loadingComplete"
                    :key="i + '-' + a"
                    :activity="activity"
                    :activityStats="activityStats[activity.id]"
                  >
                  </ActivityEngagementRow>
                </template>
              </template>

              <tr v-if="activities.length == 0 && loadingComplete">
                <td class="px-2 text-center" colspan="10">
                  <span class="text-dark-65 font-weight-bold d-block py-3">
                    {{ $t('TABLES.ACTIVITY_MESSAGES.NO_ACTIVITIES_TEXT') }}
                    <router-link
                      tag="a"
                      class="text-primary"
                      to="/project/activities"
                    >
                      {{ $t('TABLES.ACTIVITY_MESSAGES.NO_ACTIVITIES_LINK') }}
                    </router-link>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <b-alert v-if="error.active" show variant="light" class="mt-5">
          {{ error.message }}
        </b-alert>
      </div>
    </div>
  </div>
</template>

<script>
import ActivityEngagementRow from "./ActivityEngagementRow.vue";
import RoomEngagementRow from "./RoomEngagementRow.vue";
import FilterSystemActivityType from "@/view/components/filters/SystemActivityType";
import FilterSystemActivityStage from "@/view/components/filters/SystemActivityStage";

export default {
  name: "ActivityEngagementTable",

  props: {
    activities: { type: Array, required: true },
    activityStats: { type: Object, required: true },
    rooms: { type: Array, required: true },
    loadingComplete: { type: Boolean, required: true },
    error: { type: Object, required: true }
  },

  data() {
    return {
      search: "",
      filters: {
        type: null,
        stage: null
      }
    };
  },

  components: {
    ActivityEngagementRow,
    RoomEngagementRow,
    FilterSystemActivityType,
    FilterSystemActivityStage
  },

  methods: {
    activityInSearch(activity) {
      let title = activity.title.toLowerCase();
      let type = activity.activity_type.name.toLowerCase();
      let stage = activity.activity_stage.name.toLowerCase();

      if (
        title.includes(this.search.toLowerCase()) ||
        type.includes(this.search.toLowerCase()) ||
        stage.includes(this.search.toLowerCase())
      ) {
        return true;
      } else {
        return false;
      }
    },
    activityInTypeFilter: function(activity) {
      if (this.filters.type == null) {
        return true;
      } else {
        if (this.filters.type == activity.activity_type.id) {
          return true;
        } else {
          return false;
        }
      }
    },
    activityInStageFilter: function(activity) {
      if (this.filters.stage == null) {
        return true;
      } else {
        if (this.filters.stage == activity.activity_stage.id) {
          return true;
        } else {
          return false;
        }
      }
    }
  },

  computed: {
    nonEmptyRooms: function() {
      if (this.rooms.length > 0) {
        return this.rooms[0].project_rooms.filter(function( room ) { return room.activityCount > 0; });
      } else {
        return [];
      }
    },
    roomlessActivityCount: function()
    {
      let roomlessActivities = this.activities.filter(function( activity ) { return activity.project_room_id == null; });
      return roomlessActivities.length;
    }
  }
};
</script>
